.accordion {
    display: flex;
    flex-direction: column;
    margin: 20px 0;

    &__button {
        padding: 40px 0;
        border: none;
        outline: none;
        transition: 0.4s;
        margin-bottom: 20px;
        background-color: $gray;
        color: white;

        &:hover, &:active, &:focus {
            color: $white;
            background-color: $dark-blue;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: $white;
        padding: 0 15px;
        height: 0;
        overflow: hidden;
        &.active {
            height: auto;
            margin-bottom: 20px;
        }

        &--headers {
            font-size: 1rem;
            letter-spacing: .1rem;
            margin: 5px 0;

            &-minor {
                margin: 0;
                letter-spacing: .1rem;
                font-style: italic;
            }
        }

        &--details {
            min-width: 600px;
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 30px 0;
    }

    &__table {
        margin: 15px 0;
    }

    &__text {
        margin: 0 10px;
        line-height: 2;
        font-size: .85rem;

        &--row {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
        }
    }

    &__link {
        text-transform: lowercase;
        color: $light-blue;

        &:active, &:hover, &:focus {
            text-transform: lowercase;
            color: $medium-blue;
        }
    }
}

@media screen and (max-width: 768px) {
    .accordion {
        &__button {
            padding: 20px 0;
            margin-bottom: 10px;
        }

        &__content {
            flex-direction: column;

            &--headers {
                font-size: .75rem;
            }

            &--details {
                min-width: 480px;
            }
        }

        &__text {
            font-size: .75rem;
        }

        &__images {
            max-width: 50%;
        }
    }
}

.founders {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    background: url(/images/founders_bg.jpg) no-repeat center center;
    background-size: 100% 100%;
    width: 100%;
    height: 500px;

    &__quote {
        font-size: $font-size-1-25;
        letter-spacing: $font-spacing-2;
        text-align: center;
        width: 70%;
    }

    &__quoted {
        color: $medium-blue;
        font-weight: bold;
        letter-spacing: .2rem;
        text-transform: uppercase;
        font-size: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .founders {
        &__quote {
            width: 100%;
            font-size: .75rem;
        }

        &__quoted {
            margin-bottom: 20px;
        }
    }
}

.form {
    background-color: #e9e8eb;

    &__login {
        width: auto;
        align-items: center;
    }

    &__goback {
        &--link {
            margin: 8px;
        }
    }

    &__perpage {
        background: #ffffff;
        padding: 7px;
        margin: 0 10px;
        vertical-align: middle;
    }

    &__column {
        display: flex;
        flex-direction: column;
        flex: 1;

        &--center {
            align-items: center;
        }

        &--left {
            align-items: flex-start;
        }

        &--right {
            align-items: flex-end;
        }

        &--flex-grow {
            flex: 1;
        }

        &--full-width {
            width: 100%;
        }

        &--med-width {
            width: 50%;
        }

        &--small-width {
            width: 30%;
        }
    }

    &__row {
        display: flex;
        align-items: flex-start;

        &--justify-center {
            justify-content: center;
        }

        &--justify-left {
            justify-content: flex-start;
        }

        &--justify-right {
            justify-content: flex-end;
        }

        &--justify-around {
            justify-content: space-around;
        }

        &--justify-space-between {
            justify-content: space-between;
        }

        &--align-center {
            align-items: center;
        }

        &--align-flexend {
            display: flex;
            align-items: flex-end;
        }

        &--align-baseline {
            align-items: baseline;
        }

        &--roles {
            margin: 10px;
        }
    }

    &__links {
        display: flex;
        background-color: $dark-blue;
        padding: 5px 10px;
        border: 1px solid $white;
        border-radius: 4px;
        align-items: center;
        color: $white;
        margin: 10px 5px 0 0;
        width: fit-content;

        &--icon {
            padding-left: 5px;
        }
    }

    &__group {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__label {
        font-size: 1rem;
        margin-bottom: 5px;

        &--large {
            font-size: 1rem;
            margin: 10px 0;
        }
    }

    &__input {
        height: 28px;
        font-family: 'Montserrat';
        font-size: 1rem;
        margin-right: 10px;
        margin-bottom: 15px;
        color: $gray;

        &--single {
            margin-right: 0;
            max-width: 400px;
        }

        &--short {
            width: 250px;
        }

        &--search {
            flex: none;
            width: 30%;
        }

        &--filters {
            font-family: 'Montserrat';
            color: $gray;
            margin: 3px 0;
            line-height: 1.5;
            font-size: 1rem;
            width: 250px;
        }

        &--checkboxes {
            vertical-align: middle;
        }
    }

    &__filter {
        display: none;

        &--active {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &--button {
            padding: 3px 15px;
            margin: 3px 10px;
            border: none;
            font-size: 1rem;
        }
    }

    &__file-upload {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    &__file-upload-custom {
        color: #004185;
        background: transparent;
        border: 2px solid #004185;
        cursor: pointer;
        text-align: center;
        padding: 10px;
        font-size: 1rem;
        font-family: 'Montserrat';
        margin: 10px 10px 10px 0;
    }

    &__button {
        width: auto;
        margin-right: 10px;
        font-size: 1rem;

        &--export {
            width: auto;
            margin: 15px 15px 15px 0;
            font-weight: 600;

            &-form {
                width: auto;
                margin: 15px 0;
                display: inline;
            }
        }

        &--delete {
            margin: 15px 0;
            font-weight: 600;
        }

        &--add {
            display: flex;
            align-items: center;
            width: fit-content;
            padding: 5px 0;

            &-row {
                display: flex;
                align-items: center;
                margin-right: 20px;
                padding: 3px 15px 3px 8px;
            }
        }
    }

    &__icon {
        cursor: pointer;
        height: $icon-size;
        width: $icon-size;

        &--row-padding {
            padding-left: $icon-size + 10px;
        }

        &--add {
            margin-right: 5px;
        }

        &--button {
            border: none;

            &-delete {
                background: url(/images/delete2.png) center no-repeat;
                display: inline-block;
                padding: 0;
                background-size: contain;
                width: 20px;
            }

            &-delete-row {
                background: url(/images/delete2.png) center no-repeat;
                display: inline-block;
                padding: 0;
                background-size: contain;
                margin-right: 10px;
            }

            &-edit {
                background: url(/images/edit2.png) center no-repeat;
                display: inline-block;
                padding: 0;
                background-size: contain;
            }

            &-view {
                background: url(/images/view.png) center no-repeat;
                display: inline-block;
                padding: 0;
                background-size: contain;
                width: 20px;
            }

            &-add {
                margin-right: 10px;
            }
        }

        &--column {
            width: 30px;
        }
    }

    &__border {
        display: flex;
        margin: 0 10px 15px 0;
        flex: 0 1 25%;
        border: 1px solid $medium-blue;
        padding: 10px;

        &--brown {
            border: 2px solid $med-orange;
            padding: 10px;
            margin-top: 5px;
        }
    }

    &__popup {
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 50%);
    }

    &__popup-inner {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        left: 50%;
        transform: translate(-50%, 0%);
        padding: 30px 30px 10px;
        background-color: $white;
        border-radius: 3px;

        .button {
            margin-right: 10px;
        }
    }

    &__popup-text {
        color: black;
        margin-bottom: 15px;
        font-size: 1.5rem;
    }

    &__list {
        margin-bottom: 0;

        &--first-item {
            margin-top: 20px;
        }
    }

    &--column {
        flex-direction: column;
    }

    &--inline {
        display: inline;
    }

    &--mass-delete {
        color: white;
        background: #ac3d3d;
        margin-right: 10px;
    }
}

@media screen and (max-width: 768px) {
    .form {
        &__icon {
            height: 15px;
            width: 15px;
        }
    }
}

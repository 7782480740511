.contact {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 250px;

    &__column {
        display: flex;
        flex-direction: column;
        margin: 70px 50px;

        &--map {
            padding-top: 50px;
        }

        &--form {
            flex: 1;
        }
    }

    &__row {
        display: flex;

        &--item {
            flex: 1;
            font-family: $font-family;
            margin: 10px 5px;
            padding: 10px;
            text-transform: $font-uppercase;
            color: $gray;

            &-message {
                width: 100%;
            }

            &-button {
                color: white;
                flex: none;
            }
        }
    }

    &__address {
        margin: 20px 0;

        &--line {
            color: $gray;
            text-transform: $font-uppercase;
            margin: auto;
            text-align: left;
            font-style: $font-style-normal;
        }

        &--bold {
            font-weight: bold;
        }
    }
}

@media screen and (max-width: 768px) {
    .contact {
        display: flex;
        flex-direction: column;
        padding: 0;

        &__column {
            margin: 10px 25px;

            &--map {
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                padding: 15px 8px;
            }

            &--form {
                width: 80%;
            }
        }

        &__row {
            display: flex;
            flex-wrap: wrap;

            &--item {
                &-button {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}

.chart {
    max-width: 450px;
    max-height: 450px;

    &__assets {
        margin-bottom: 30px;
    }

    &__distributions {
        max-height: 850px;
    }
}

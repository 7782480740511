.portfolio {

    &__header {
        margin: 50px 0;
    }

    &__accordion {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 55%;

        &--content {
            display: flex;
            align-items: center;
            background-color: #ffffff;
            height: 0;
            overflow: hidden;
            transition: all 200ms;

            &.active {
                height: auto;
                margin-bottom: 20px;
            }

            &-details {
                padding: 0 20px;
            }
        }

        &--images {
            display: flex;
        }
    }
}

@media screen and (max-width: 1024px) {
    .portfolio {
        &__accordion {
            width: 70%;

            &--content {
                flex-wrap: wrap;
                align-content: space-between;
                justify-content: center;

                &-details {
                    padding: 20px;
                    text-align: center;
                }
            }

            &--images {
                padding: 20px;
            }
        }
    }
}

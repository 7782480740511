.mobile {
    &__icon {
        &--side-nav {
            display: none;
        }
    }
}

@media screen and (max-width: 768px) {
    .mobile {
        &__icon {
            &--side-nav {
                display: flex;
                align-items: center;
                width: 15px;
                margin-left: 10px;
            }
        }
    }
}

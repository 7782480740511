.account {
    &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &__cards {
        background: white;
        padding: 20px;
        position: relative;
        min-width: 25%;
        margin: 15px 40px;

        &--flex-grow {
            flex: 1;
        }
    }

    &__button {
        &--icon {
            border: none;
            width: 30px;
            height: 30px;
        }
    }

    &__icon {
        position: absolute;
        margin: 10px;
        cursor: pointer;
        width: 30px;
        height: 30px;

        &--labels {
            max-width: 20px;
            margin-right: 8px;
        }

        &--button {
            border: none;
            background-color: transparent;
            padding: 0;
            margin: 0;
        }

        &--edit {
            background: url("/images/edit2.png") center no-repeat;
            background-size: 30px;
            top: 0;
            right: 0;
        }

        &--save {
            background: url("/images/save2.png") center no-repeat;
            background-size: 30px;
            top: 0;
            right: 50px;
        }

        &--delete {
            top: 0;
            right: 0;
        }
    }

    &__headers {
        margin-top: 30px;

        &--title {
            margin: 50px 0 0 0;
        }

        &--bold {
            font-weight: 600;
            color: $medium-blue;
        }

        &--tpname {
            margin: 5px 0 25px 0;
        }
    }

    &__paragraph {
        line-height: 1.25;
        font-style: italic;
    }

    &__popup {
        display: none;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 50%);
        z-index: 5;
    }

    &__popup-inner {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        left: 50%;
        transform: translate(-50%, 0%);
        padding: 30px;
        background-color: $white;
        max-width: fit-content;
        top: 30%;

        .button {
            color: $white;
            background-color: $dark-blue;
            margin-right: 10px;
        }
    }

    &__popup-text {
        color: black;
        margin-bottom: 15px;
        font-size: 1.5rem;
    }

    &__button {
        display: none;

        &--active {
            display: block;
            position: absolute;
            left: 195px;
            bottom: 50px;
        }
    }
}

@media screen and (max-width: 768px) {
    .account {
        &__icon {
            margin: 10px;

            &--edit {
                background-size: 20px;
            }
        }

        &__button {
            &--active {
                left: 130px;
            }
        }
    }
}

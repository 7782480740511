.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: lightgray;
    color: white;
    width: 100%;
    height: 600px;
    justify-content: flex-start;

    &__background-image {
        &--home {
            background: url('/images/marcato_bw.jpg') no-repeat center center;
            background-size: 100%;
        }

        &--contact {
            background: url('/images/contact_header.jpg') no-repeat center center;
            background-size: 100% 100%;
        }
    }

    &__statement {
        text-align: center;
        flex: 0 1 auto;
        padding: 0 70px;
        margin-top: 100px;
        margin-bottom: 30px;
        background: $medium-blue;

        &--left {
            align-self: flex-start;
            margin-left: 100px;
            margin-top: 0;
            justify-items: stretch;
            top: 50%;
            position: relative;
            transform: translateY(-70%);
        }
    }
}

@media screen and (max-width: 768px) {
    .hero {
        height: auto;
        padding: 6% 0;
        background-size: auto;

        &__statement {
            text-align: center;
            flex: 0 1 auto;
            padding: 0 70px;
            margin-top: 166px;
            margin-bottom: 50px;

            &--contact {
                text-align: center;
                flex: 0 1 auto;
                padding: 0 20px;
                margin-top: 85px;
                margin-bottom: 50px;
                width: 50%;
            }

            &--left {
                margin-left: 0;
                display: flex;
                align-self: center;
                justify-content: center;
            }
        }
    }
}

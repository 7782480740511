.dashboard {
    &__outer {
        display: flex;
        background-color: $light-gray;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 20px 20px;
        overflow-x: scroll;

        &--center {
            align-items: center;
        }
    }

    &__header {
        font-size: 2.5rem;
        font-weight: 600;
        margin: 10px 0 30px 0;
    }

    &__menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 215px;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        overflow: hidden;
        &.active {
            height: 100%;
        }

        &--container {
            flex: 0 1 210px;
            display: flex;
            flex-direction: column;
        }

        &--orange {
            background-color: $med-orange;
        }

        &--blue {
            background-color: $medium-blue;
        }

        &--show {
        }

        &--toggle {
            padding: 8px 0;
            font-size: 1rem;
            width: 100%;
            position: relative;


            &-blue {
                color: $medium-blue;
                border: 2px solid $medium-blue;
                background-color: $white;
                bottom: 0;
            }

            &-orange {
                color: $med-orange;
                border: 2px solid $med-orange;
                background-color: $white;
                top: 0;
            }
        }

        &--button {
            display: flex;
            flex-direction: column;
            font-weight: 600;
            letter-spacing: 0;
            align-items: center;
            color: white;
            font-size: 1rem;
            padding: 35px 0;

            &-blue {
                background-color: $medium-blue;
                color: $white;

                &:hover,
                &:active,
                &:visited,
                &:focus,
                &:hover,
                &-current {
                    letter-spacing: 0;
                    color: white;
                    background-color: $dark-blue;
                }
            }

            &-orange {
                background-color: $med-orange;
                color: $white;

                &:hover,
                &:active,
                &:visited,
                &:focus,
                &:hover,
                &-current {
                    letter-spacing: 0;
                    color: white;
                    background-color: $dark-orange;
                }
            }
        }

        &--items {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 0;
            margin: 0;
        }

        &--item {
            display: block;

        }

        &--icon {
            max-width: 60px;
            margin-bottom: 5px;
        }

        &--labels {
            display: flex;
            flex-wrap: wrap;
            max-width: 30px;
            text-align: center;
            justify-content: center;
        }

        &--mobile {
            display: none;
        }
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__card {
        display: flex;
        flex: 1;
        background-color: $white;
        align-items: center;
        align-content: flex-start;

        &--wrap {
            flex-wrap: wrap;
            padding: 30px;
        }

        &--panel {
            flex-direction: column;
            max-width: 100%;
            margin-bottom: 30px;
            padding: 30px;
        }

        &--block {
            flex-direction: column;
            min-width: 485px;
            justify-content: flex-end;
            min-height: 350px;
            margin: 20px 0;
            flex: 0;

             &-distributions {
                 background: url('/images/login-distributions.png') no-repeat center center;
             }

            &-docs {
                margin: auto;
            }

            &-performance {
                background: url('/images/insights_header.jpg') no-repeat center center;
            }
        }

        &--labels {
            background: $medium-blue;
            width: 100%;
            text-align: center;
            padding: 10px 0;
            font-size: 2rem;
            color: $white;

            &-dk-blue {
                background-color: $dark-blue;
            }

            &-transparent {
                opacity: .8;
            }
        }
    }

    &__font{
        &--documents {
            font-weight: 300;
            margin: 8px 0;
            color: grey;
            text-transform: capitalize;
        }
    }

    &__table {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        max-height: 360px;
        min-width: 550px;

        &--row {
            display: flex;
            justify-content: space-between;
            line-height: 2;
            padding: 0 10px;

            &-grey {
                background: $light-gray;
            }
        }

        &--data {
            font-size: $font-size-1-25;
            color: $gray;
            letter-spacing: .1rem;
        }

        &--no-overflow {
            overflow: hidden;
        }
    }

    &__button {

        &--admin {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: $dark-blue;
            min-width: 275px;
            min-height: 225px;
            margin: 30px;
        }
    }

    &__icons {
        &--pdf {
            max-height: 25px;
            margin-right: 8px;
        }

        &--container {
            padding: 10px;
        }
    }
}

@media screen and (max-width: 768px) {
    .dashboard {

        &__inner {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin: 0 15px;
            padding: 20px 0;

            &--center {
                align-items: center;
            }
        }

        &__header {
            font-size: 2rem;
            margin: 10px 0 20px 0;
        }

        &__menu {
            &--container {
                position: absolute;
                top: 0;
                left: -100%;
                &-visible {
                    left: 0;
                }
            }


            &--items {
                &-visible {
                    left: 0;
                }
            }

            &--toggle {

                &-visible {
                    left: 0;
                }
            }

            &--button {
                font-size: .85rem;
                padding: 30px 0;
            }

            &--icon {
                max-width: 30px;
            }
        }

        &__card {
            &--panel {
                margin-bottom: 15px;
                padding: 0;

                &-mobile {
                    display: none;
                }
            }

            &--labels {
                font-size: 1rem;
            }

            &--block {
                margin-bottom: 15px;
                flex: 1;
            }
        }

        &__button {
            &--admin {
                min-width: 225px;
                min-height: 200px;
            }
        }

        &__asset-chart {
            max-width: 250px;
            max-height: 250px;
            margin-bottom: 15px;
        }

        &__table {
            max-height: 175px;
            min-width: 360px;

            &--row {
                justify-content: space-evenly;
                padding: 0;
            }

            &--data {
                font-size: 1rem;
            }
        }

        &__font {
            &--documents {
                font-weight: 300;
                color: grey;
                text-transform: capitalize;
                font-size: 1rem;
                margin: 15px 0;
            }
        }

        &__icons {
            &--pdf {
                max-height: 35px;
            }
        }

        &__card-block {
            &--docs {
                margin: 3px 0;
            }
        }
    }
}

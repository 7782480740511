@font-face {
    font-family: 'Montserrat';
    font-weight: normal;
    src: url(/fonts/Montserrat-Light.ttf);
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    src: url(/fonts/Montserrat-Light.ttf);
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 100;
    src: url(/fonts/Montserrat-ExtraLight.ttf);
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 800;
    src: url(/fonts/Montserrat-Bold.ttf);
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    src: url(/fonts/Montserrat-ExtraBold.ttf);
}


html, body {
    overflow-x: hidden;
    margin: 0;
    font-family: $font-family;
}

a,
.links, .link {
    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        list-style-type: none;
        text-transform: $font-uppercase;
        letter-spacing: $font-spacing-1;
    }
}

ul, li,
.links, .link {
    list-style-type: none;
    padding: 0;
}

h1, h2, h3, h4, h5, h6,
.header1, .header2, .header3, .header4, .header5, .header6 {
    font-family: $font-family;
    color: white;
    text-transform: $font-uppercase;
    letter-spacing: $font-spacing-2;
    margin: 0;

    &--blue {
        color: $medium-blue;
    }

    &--gray {
        color:$gray;
    }

    &--lightgray {
        color: $light-gray;
    }

    &--camelcase {
        text-transform: capitalize;
    }

    &--regular {
        font-weight: $font-regular;
    }
}

h1,
.header1 {
    font-size: $font-size-3;
    font-weight: $font-regular;
    margin: 10px 0;
}

h2,
.header2 {
    font-size: $font-size-2;
    font-weight: $font-regular;
    margin: 10px 0;
}

h3,
.header3 {
    font-size: $font-size-1-5;
    font-weight: $font-regular;
}

h4,
.header4 {
    font-size: $font-size-1-25;
}

h6,
.header6 {
    letter-spacing: 0;
    font-weight: 400;
}

button,
.button {
    text-align: center;
    cursor: pointer;
    padding: 8px 16px;
    font-size: $font-size-1;
    font-family: $font-family;
    text-transform: $font-uppercase;

    &__no-grow {
        width: fit-content;
    }

    &__margin-top {
        margin-top: 8px;
    }


    &--blue, &--blue:focus, &--blue:hover {
        color: $medium-blue;
        background: transparent;
        border: 2px solid $medium-blue;
    }

    &--blue-white, &--blue-white:focus, &--blue-white:hover {
        color: $medium-blue;
        background: white;
        border: 2px solid $medium-blue;
    }

    &--dkblue, &--dkblue:focus, &--dkblue:hover {
        color: $dark-blue;
        background: transparent;
        border: 2px solid $dark-blue;
    }

    &--dkblue-white, &--dkblue-white:focus, &--dkblue-white:hover {
        color: $dark-blue;
        background: white;
        border: 2px solid $dark-blue;
    }

    &--white, &--white:focus, &--white:hover {
        color: white;
        background: transparent;
        border: 2px solid white;
    }

    &--gray, &--gray:focus, &--gray:hover {
        color: $gray;
        background: transparent;
        border: 2px solid $gray;
    }

    &--red, &--red:focus, &--red:hover {
        color: $red;
        background: transparent;
        border: 2px solid $red;
    }

    &--orange, &--orange:focus, &--orange:hover {
        color: $orange;
        background: transparent;
        border: 2px solid $orange;
    }

    &--green, &--green:focus, &--green:hover {
        color: $green;
        background: transparent;
        border: 2px solid $green;
    }

    &--blue-opaque, &--blue-opaque:focus {
        color: $white;
        background: $medium-blue;
        border: 2px solid $medium-blue;
        &:active,
        &.active,
        &:hover {
            background: RGBA(red($medium-blue), green($medium-blue), blue($medium-blue), 0.8);
        }
    }

    &--dkblue-opaque, &--dkblue-opaque:focus, &--dkblue-opaque:hover {
        color: $white;
        background: $dark-blue;
        border: 2px solid $dark-blue;
    }

    &--red-opaque, &--red-opaque:focus, &--red-opaque:hover {
        color: $white;
        background: $red;
        border: 2px solid $red;
    }

    &--orange-opaque, &--orange-opaque:focus, &--orange-opaque:hover {
        color: $white;
        background: $orange;
        border: 2px solid $orange;
    }

    &--med-orange-opaque, &--med-orange-opaque:focus, &--med-orange-opaque:hover {
        color: $white;
        background: $med-orange;
        border: 2px solid $med-orange;
    }

    &--dark-orange-opaque, &--dark-orange-opaque:focus, &--dark-orange-opaque:hover {
        color: $white;
        background: $dark-orange;
        border: 2px solid $dark-orange;
    }
}

img {
    max-width: 100%;
}

p,
.paragraph {
    margin: 0;
    line-height: 2;
}

@media screen and (max-width: 768px) {
    h1, .header1 {
        font-size: $font-size-1-75;
    }

    h2, .header2 {
        font-size: $font-size-1-25;
        padding: 10px 10px 10px 0;
    }

    h3, .header3 {
        font-size: 1rem;
    }

    h4, .header4 {
        font-size: .9rem;
    }

    button, .button {
        font-size: $font-size-1;
        padding: 5px 10px;
    }

    p,
    .paragraph {
        font-size: .75rem;
    }
}

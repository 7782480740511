.columnSelector {
    &__columns {
        display: none;
        padding: 10px;
        background: white;

        &--visible {
            display: flex;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 50%;
            flex-wrap: wrap;
            flex-direction: column;
            padding: 10px;
            border: 1px solid $dark-blue;
            box-shadow: 0 0 8px 1px $dark-blue;
            border-radius: 3px;

        }
    }

    &__popup {
        &--header {
            text-transform: capitalize;
            letter-spacing: unset;
            font-weight: 800;
            margin-bottom: 15px;
        }

        &--close {
            position: absolute;
            right: 2%;
            height: 15px;
            cursor: pointer;
        }
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 250px);
    background-color: #e9e8eb;
}

.letter-spacing-1 {
    letter-spacing: $font-spacing-1;
}

.letter-spacing-2 {
    letter-spacing: $font-spacing-2;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.blue {
    color: $medium-blue;
}

.dark-blue {
    color: $dark-blue;
}

.gray {
    color: $gray;
}

.green {
    color: $green;
}

.red {
    color: $red;
}

.white {
    color: $white;
}

.black {
    color: $black;
}

.capitalize {
    text-transform: capitalize;
}

.lowercase {
    text-transform: lowercase;
}

.bold {
    font-weight: 600;
}

.italic {
    font-style: italic;
}

.readonly {
    color: $gray;
    background-color: $light-gray;
}

.property {
    &__grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin: 20px;
        align-items: baseline;

        &--image {
            display: block;
            margin: 20px;
            background-position: center;
            background-repeat: no-repeat;
            min-height: 300px;
            min-width: 400px;
            background-size: cover;
            cursor: pointer;
            flex: 1;
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
    }

    &__row {
        margin: 0;
    }

    &__icon {
        max-height: 25px;
        position: absolute;
        top: 5%;
        cursor: pointer;

        &--pencil {
            right: 90%;
        }

        &--delete {
            right: 85%;
        }
    }

    &__header {
        margin: 0 20px;
    }

    &__text {
        margin: 0 20px;
        line-height: 2;

        &--website {
            text-transform: lowercase;
            font-style: italic;
        }
    }

    &__single {

        &--data {
            display: flex;
            flex: 3;
        }
    }

    &__delete {
        position: relative;
    }

    &__button {
        &--delete {
            display: block;
            position: relative;
            left: 375px;
            bottom: 97px;
        }
    }

    &__card {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        background-color: $white;
    }

    &__asset {
        &--column {
            display: flex;
            flex-direction: column;
            margin: 0 0 30px 0;
            align-items: center;
        }

        &--chart {
            min-width: 450px;
        }
    }

}

@media screen and (max-width: 768px) {
    .property {
        &__single {
            &--data {
                flex: unset;
            }
        }

        &__header {
            margin: 0;
        }

        &__text {
            margin: 0;
        }
    }
}

.row {
    display: flex;
    justify-content: space-between;

    &__white {
        background-color: $white;
    }

    &__justify--center {
        justify-content: center;
    }
}

.column {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .row {
        margin: 8px 0;
    }
}

.msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-style: italic;
    padding: 0;
    margin: 0;
    line-height: 2;
    text-align: center;

    &__error {
        color: $red;
    }

    &__success {
        color: $green;
        font-weight: 600;
    }

    &__container {
        margin: 0;
        padding: 0;
        min-height: 30px;
    }

    &__external {
        text-transform: capitalize;
        font-size: 1.15rem;
        color: $red;
        text-align: center;
        letter-spacing: 0;
    }

    &__link {
        text-align: center;
        text-transform: uppercase;
        color: $gray;
    }

    &__permissions{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;
    }
}

.numbers {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: $light-blue;
    background: url(/images/statistics_bg.jpg) no-repeat center center;
    color: white;
    width: 100%;
    height: 600px;

    &__statement {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        color: #004185;
        width: 40%;
        height: 100px;
    }

    &__table {
        display: flex;

        &-row {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 40px 80px;
        }

        &-header {
            letter-spacing: $font-spacing-2;
        }

        &-statistic {
            font-size: $font-size-3;
        }
    }

    &__header {
        font-size: $font-size-2-5;
    }
}

@media screen and (max-width: 768px) {
    .numbers {
        height: auto;
        background-size: cover;
        padding: 6% 0;

        &__statement {
            display: block;
            justify-content: center;
            align-items: center;
            background: white;
            color: #004185;
            width: 80%;
            height: auto;
        }

        &__table {
            display: block;
        }
    }
}

.flextable {
    display: none;
    flex-direction: column;

    &__active {
        display: flex;
        margin: 10px 0;
    }

    &__tab-top {
        margin-top: 0;
    }

    &__statistics {
        background-color: $light-blue;
        margin: 30px;
        padding: 30px;
        width: 600px;

        &--header {
            margin-top: 0;
            font-weight: 600;
            padding: 0 0 20px 15px;
            letter-spacing: .1rem;
        }
    }

    &__distributions {
        width: 600px;

        &--header {
            font-weight: 600;
            margin-bottom: 10px;
            margin-left: 50px;
        }
    }

    &__row {
        display: flex;
        justify-content: flex-start;
        line-height: 3;

        input[type="checkbox"] {
            margin: 0 10px;
        }

        &--justify-between {
            justify-content: space-between;
        }

        &--no-line-spacing {
            line-height: unset;
        }


        &--headers {
            align-items: stretch;
            font-weight: 600;
            color: $white;
            background-color: $dark-blue;
        }

        &--data {
            align-items: center;
        }

        &--blue {
            background-color: $white;
        }

        &--grey {
            background-color: $light-gray;
        }

        &--distributions {
            line-height: 2;
            padding: 0 50px;
        }
    }

    &__column {
        display: flex;
        flex-direction: column;

        &--headers {
            font-weight: 600;
            color: $white;
        }
    }

    &__data {
        flex: 1;
        padding: 0 15px;
        line-height: 3;

        &--statistics {
            letter-spacing: .1rem;
            font-weight: 500;
            line-height: 1.5;
        }

        &--distributions {
            letter-spacing: .1rem;
            color: $gray;
        }
    }

    &__tabs {
        border-radius: 8px 8px 0 0;
        margin-right: 3px;
        width: fit-content;
        color: $white;
        background-color: $medium-blue;
        border: 2px solid $medium-blue;

        &:hover {
            opacity: .5;
        }

        &--active {
            color: $white;
            background-color: $dark-blue;
            border: 2px solid $dark-blue;
        }
    }
}

.distributions {
    &__cards {
        display: flex;
        align-items: baseline;
        background: white;
        max-height: 350px;
        padding: 5px 30px;
        margin: 50px 0 0 0;

        &--section {
            display: flex;
            flex-direction: column;
            width: 30%;
        }
    }

    &__headers {
        margin: 0;
        line-height: 2;

        &--title {
            margin-bottom: 20px;
        }
    }

    &__images {
        max-width: 60%;
        margin-bottom: 25px;
    }
}

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Globals
@import "variables";
@import "globals";
@import "helpers";


//Components (Block - BEM)
@import "components/navbar";
@import "components/footer";
@import "hero";
@import "assets";
@import "founders";
@import "numbers";
@import "contact";
@import "dashboard";
@import "form";
@import "property";
@import "table";
@import "container";
@import "msg";
@import "distributions";
@import "documents";
@import "account";
@import "structure";
@import "search";
@import "accordion";
@import "mobile";
@import "flextable";
@import "pager";
@import "progress";
@import "chart";
@import "portfolio";
@import "columnSelector";

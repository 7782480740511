.table {
    margin: 15px 0;
    border-collapse: collapse;
    overflow: hidden;
    width: 100%;

    &__head,
    &__head.table__row {
        background: $dark-blue;
    }

    &__headers {
        padding: 10px 10px;
        color: #fff;
        font-weight: 500;
        text-align: left;

        &-delete {
            background-color: $medium-blue;
            text-align: center;

            &-icon {
                max-width: 20px;
            }
        }
    }

    &__row {
        border-bottom: solid 1px #cbcbcb;

        &:hover {
            background: $light-blue;
            color:#fff;
            cursor:pointer;
        }
    }

    &__row:last-child {
        border-bottom: solid 3px $light-blue;
    }

    &__row:nth-child(2n+2) {
        background: white;

        &:hover {
            background: $light-blue;
            color:#fff;
            cursor:pointer;
            border-collapse: collapse;
        }
    }

    &__data, &__data:focus, &__data:hover {
        line-height: 2;
        padding: 10px 10px;
        letter-spacing: normal;
        font-size: .8rem;
    }

    &__delete-selector {
        background-color: $gray;
        opacity: 0.25;
        text-align: center;
        max-width: 18px;
    }

    &__page {
        padding: 0 20px;
    }

    &--wrap {
        display: flex;
    }
}

#document_type, #document_name, #created_at {
    width: 350px;
}

@media screen and (max-width: 768px) {
    .table {
        margin: 15px auto;

        &__headers {
            font-size: .5rem;
            padding: 3px;
            text-align: left;
        }

        &__data {
            font-size: .5rem;
            padding: 3px;
        }
    }
}

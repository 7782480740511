.assets {
    display: flex;
    flex-direction: column;
    background: $light-blue;
    background: url('/images/marcato_blue.jpg')no-repeat center center;
    background-size: 100%;
    width: 100%;
    height: 600px;

    &__statement {
        display: flex;
        flex-wrap: wrap;
        margin: 100px 200px;
        padding: 40px;
        background: $medium-blue;
        width: 325px;
    }

    &__button {
        margin-left: 0;
    }
}

@media screen and (max-width: 768px) {
    .assets {
        height: auto;
        padding: 6% 0;

        &__statement {
            margin: auto;
            text-align: center;
        }
    }
}

.search {
    &__delete-icon {
        position: relative;
        max-width: 15px;
        max-height: 15px;
        transform: translateY(10%);
        right: 20px;
        cursor: pointer;
    }
}

.loading {
    display: flex;
    margin: auto;
    width: 50px;
    height: 50px;
    border: 3px solid $white;
    border-radius: 50%;
    border-top-color: $dark-blue;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;

    &--background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #00000061;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 60px;
        color: #c7c7c7;
    }

    &--inner {
        position: absolute;
        top: 60%;
    }
}

@keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}

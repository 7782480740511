.footer {
    background: $gray;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 250px;

    &__elements {
        display: flex;
        align-items: center;
    }

    &__element {
        display: flex;
        margin: 0 20px;

        &--contact {
            a {
                color: white;
            }
        }

        &--contact, &--break {
            margin: 0 5px;
            font-weight: 600;
            font-size: .75rem;
        }

        &--social {
            cursor: pointer;
            max-height: 35px;
            width: auto;
        }
    }
}

@media screen and (max-width: 768px) {
    .footer {
        height: auto;

        &__elements {
            display: block;
            padding-top: 10px;
        }

        &__element {
            display: block;
            margin: 20px 20px;

            &--contact {
                a {
                    color: white;
                }
            }

            &--break {
                display: none;
            }
        }
    }
}

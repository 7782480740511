.navbar {
    display: flex;
    position: relative;
    justify-content: space-between;
    background: white;
    padding: 0;

    &--absolute {
        position: absolute;
        overflow: hidden;
        max-width: 100%;
    }

    &__logo {
        height: 70px;
        margin: 30px;
    }

    &__links {
        display: flex;
        margin: 60px;
    }

    &__link {
        padding: 0 30px;
        font-weight: $font-bold;

        &-login {
            color: $light-blue;
        }
    }

    &__button {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .navbar {

        &__button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-image: url('/images/mobile-menu.png');
            width: 30px;
            height: 30px;
            background-size: contain;
            background-repeat: no-repeat;
            margin: 30px;

            &--icon {
                max-height: 20px;
            }
        }

        &__logo {
            margin: 30px 15px;
            max-height: 40px;
        }

        &__links {
            display: flex;
            background-color: white;
            border: 1px solid #c9c9c9;
            transition: 200ms ease-in-out;
            position: absolute;
            margin: 0;
            padding: 35px;
            top: 0;
            right: -100%;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            &--visible {
                right: 0;
            }
        }

        &__link {
            padding: 30px;
            font-weight: bold;

            &--login {
                color: #325B84;
            }
        }

    }
}

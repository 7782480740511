.documents {
    &__table {
        text-align: left;
        border-collapse: collapse;
        width: 40%;
        margin-top: 30px;

        &--row {

        }

        &--data {
            &--center {
                text-align: center;
            }
        }
    }
}

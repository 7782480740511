// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$dark-blue: #0B2B4F;
$medium-blue:#004185;
$light-blue: #366C9B;
$gray: #2f302f;
$light-gray: #e9e8eb;
$orange: #994D2F;
$med-orange: #783319;
$dark-orange: #4d2110;
$red: #bd4255;
$green: #229257;
$white: #ffffff;
$black: #000000;

$font-family: 'Montserrat';

$font-size-3: 3rem; //browser 'xxx-large'
$font-size-2: 2rem; //browser 'xx-large'
$font-size-2-5: 2.5rem;
$font-size-1-75: 1.75rem;
$font-size-1-5: 1.5rem;
$font-size-1-25: 1.25rem; //browser 'large'
$font-size-1: 1rem;
$font-size-_75: .75rem;

$font-bold: bold;
$font-regular: 300;
$font-uppercase: uppercase;
$font-spacing-1: .1rem;
$font-spacing-2: .2rem;
$font-style-normal: normal;


$icon-size: 30px;

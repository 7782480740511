.pager {
    cursor: pointer;
    padding: 0;
    border: none;
    background-color: transparent;

    &__number {
        color: $medium-blue;
        font-size: 1rem;
        font-weight: 500;
        padding: 0 10px;
    }

    &__icon {
        height: 20px;
        width: 20px;
    }
}
